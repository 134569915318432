<template>
  <el-row class="w-full">
    <el-col :span="24" v-if="images?.length > 0 && isViewImage">
      <el-image
        v-for="image of images"
        :key="image"
        class="mx-1 image-200 border"
        :src="imageUrl(image, true, 300)"
        :preview-src-list="images.map(i => imageUrl(i, true))"
        title="Click to preview"
        lazy
      >
        <template #error>
          <slot-error />
        </template>
      </el-image>
    </el-col>
    <el-col :span="24">
      <el-button type="text" @click="isViewImage = !isViewImage">
        <span class="custom-text-mini">
          {{ isViewImage ? 'Hide image' : 'Show image' }}
        </span>
      </el-button>
    </el-col>
  </el-row>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { imageUrl } from '@/utils/image'
import SlotError from '@/components/common/SlotError.vue'

export default defineComponent({
  components: { SlotError },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const isViewImage = ref(false)
    return { isViewImage, imageUrl }
  },
})
</script>
