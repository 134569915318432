
import { defineComponent, ref, reactive } from 'vue'
import { imageUrl } from '@/utils/image'
import PreviewImage from '@/components/common/PreviewImage.vue'
import { ARTWORK_SPACES } from '@/utils/constants'
import ChooseCatalogCode from './ChooseCatalogCode.vue'
import UploadArtworksDialog from './UploadArtworksDialog.vue'
import { ArrowDown } from '@element-plus/icons'

export default defineComponent({
  components: {
    PreviewImage,
    ChooseCatalogCode,
    ArrowDown,
    UploadArtworksDialog,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const columns = [
      {
        title: 'Variant title',
        dataIndex: 'title',
        minWidth: 20,
      },
      {
        title: 'Option 1',
        dataIndex: 'option1',
        minWidth: 10,
        sort: true,
      },
      {
        title: 'Option 2',
        dataIndex: 'option2',
        minWidth: 10,
        sort: true,
      },
      {
        title: 'Option 3',
        dataIndex: 'option3',
        minWidth: 10,
        sort: true,
      },
      {
        title: 'Catalog',
        dataIndex: 'catalogName',
        minWidth: 10,
      },
      {
        title: 'Code',
        dataIndex: 'catalogVariantCode',
        minWidth: 10,
      },
      {
        title: 'Design Qty',
        dataIndex: 'designQty',
        minWidth: 10,
      },
      {
        title: 'Artworks',
        dataIndex: 'artwork',
        minWidth: 15,
        align: 'center',
      },
      {
        title: 'Actions',
        minWidth: 10,
      },
    ]

    const state = reactive({
      showModalUploadDesign: false,
      variants: ref<any[]>([]),
      catalogDesigns: ref<any[]>([]),
      selectedVariants: ref<any[]>([]),
      multipleSelection: ref<any[]>([]),
      isEditMode: ref(false),
    })

    const onSelectVariants = (data: any) => {
      state.multipleSelection = data
    }

    state.variants = [...props.documents]

    const uploadArtwork = (data: any[]) => {
      state.catalogDesigns = []
      state.selectedVariants.length = 0
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        let findIndex = state.variants.findIndex(
          (v: any) => v.sku === element.sku
        )
        if (findIndex !== -1) {
          state.selectedVariants = [...state.selectedVariants, findIndex]
        }

        if (
          element.catalogs[0].catalogVariants[0].designQty &&
          element.catalogs[0].catalogVariants[0].designQty > 0
        )
          var designQtyLength = element.catalogs[0].catalogVariants[0].designQty
        for (let i = 0; i < designQtyLength; i++) {
          let imgSrc
          if (element.catalogs[0].designs.length > i)
            imgSrc = element.catalogs[0].designs[i].designSrc

          state.catalogDesigns = [
            ...state.catalogDesigns,
            {
              designSrc: imgSrc ? imgSrc : '',
              mockupSrc: '',
              position: '',
              name: '',
              width: '',
              height: '',
              template: '',
            },
          ]
        }
      }
      state.showModalUploadDesign = true
    }

    const onUploadFileDesign = (data: any) => {
      state.catalogDesigns[data.index].designSrc = data.file.path
      for (let index = 0; index < state.selectedVariants.length; index++) {
        const variantIndex = state.selectedVariants[index]
        state.variants[variantIndex].catalogs[data.indexCatalog].designs[
          data.index
        ] = {
          designSrc: data.file.path,
          mockupSrc: '',
          position: '',
          name: '',
          width: '',
          height: '',
          template: '',
        }
      }
    }

    const onSubmitDesign = () => {
      emit('callback', state.variants)
      state.showModalUploadDesign = false
    }

    const previewImageDialog = ref<InstanceType<typeof PreviewImage>>()
    const onClickPreviewImage = (path: string) => {
      previewImageDialog.value?.toggle(imageUrl(path))
    }

    const mappedCatalog = ref('')
    const getMappedCatalog = () => {
      if (!mappedCatalog.value) {
        mappedCatalog.value = state.variants.find(
          item => item.catalogId
        )?.catalogId
      }
      return mappedCatalog.value
    }

    const changeCatalogCodeDialog =
      ref<InstanceType<typeof ChooseCatalogCode>>()
    const onChangeCatalogCode = async (variant: any) => {
      let suggestCatalog = variant?.catalogId
      if (!suggestCatalog) {
        suggestCatalog = await getMappedCatalog()
      }
      changeCatalogCodeDialog.value?.toggle(variant, suggestCatalog)
    }

    const callbackChangeVariantCode = async (data: any) => {
      const productVariant = data.productVariant
      if (productVariant && state.variants) {
        const indexVariantChanged = state.variants?.findIndex(
          variant => variant._id === productVariant?._id
        )

        if (indexVariantChanged !== -1) {
          productVariant.catalogId = data?.selectedCatalog?.catalogId
          productVariant.catalogName = data?.selectedCatalog?.catalogName
          productVariant.designQty = data?.designQty

          productVariant.catalogVariantId = data?.selectedVariant?._id
          productVariant.catalogVariantCode = data?.variantCode
          productVariant.catalogVariantColorName =
            data?.selectedVariant?.color?.name
          productVariant.catalogVariantSizeName =
            data?.selectedVariant?.size?.name

          productVariant.designQty = data?.designQty

          state.variants[indexVariantChanged] = productVariant
        }
      }

      emit('callbackUpdateVarCode', data)
    }

    const uploadArtworksDialog =
      ref<InstanceType<typeof UploadArtworksDialog>>()

    const onUploadArtworksDialog = async (variant: any) => {
      uploadArtworksDialog.value?.toggle(variant)
    }

    const callbackUploadArtworks = (data: any) => {
      const productVariant = data.variant

      if (productVariant && state.variants) {
        if (state.multipleSelection.length < 2) {
          const indexVariantChanged = state.variants?.findIndex(
            variant => variant._id === productVariant?._id
          )
          if (indexVariantChanged !== -1) {
            state.variants[indexVariantChanged] = productVariant
          }
        } else {
          state.multipleSelection.forEach((item: any) => {
            const indexVariantChanged = state.variants?.findIndex(
              variant => variant._id === item?._id
            )

            if (indexVariantChanged !== -1) {
              state.variants[indexVariantChanged] = {
                ...item,
                ...productVariant,
              }
            } else {
              state.variants.push({
                ...item,
                ...productVariant,
              })
            }
          })
        }
      }
      emit('callback', state.variants)
    }

    const uploadDesigns = () => {
      if (state.multipleSelection.length === 1) {
        onUploadArtworksDialog(state.multipleSelection[0])
      } else {
        onUploadArtworksDialog(state.multipleSelection)
      }
    }

    return {
      columns,
      state,
      imageUrl,
      onUploadFileDesign,
      uploadArtwork,
      onSubmitDesign,
      previewImageDialog,
      onClickPreviewImage,
      ARTWORK_SPACES,
      changeCatalogCodeDialog,
      onChangeCatalogCode,
      mappedCatalog,
      getMappedCatalog,
      callbackChangeVariantCode,
      onSelectVariants,
      uploadArtworksDialog,
      onUploadArtworksDialog,
      callbackUploadArtworks,
      uploadDesigns,
    }
  },
})
