
import { defineComponent, reactive, ref } from 'vue'
// import { useNotification } from '@/composables'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import { formatterDollars } from '@/utils/formatter'
import { catalogTypes } from '@/utils/catalog'
import ChooseCatalogCodePreview from '@/components/orders/ChooseCatalogCodePreview.vue'

export default defineComponent({
  components: { ChooseCatalogCodePreview },
  setup(props, context) {
    const showModalVisible = ref(false)
    const isLoading = ref(true)
    const productVariant = ref()
    const variants = ref([])
    const isShowAllVariant = ref(true)
    const state = reactive({
      catalogs: ref<any[]>([]),
      selectedCatalog: ref<any>(),
      form: {
        catalog: '',
        type: 'pod',
      },
    })
    const info = JSON.parse(localStorage.getItem('info') || '')

    // const { error, success } = useNotification()
    const { getCatalogs, getCatalog } = userCatalogRepositories()
    const getListProductCatalog = async () => {
      isLoading.value = true
      let res = await getCatalogs({
        page: 1,
        limit: 10000,
        basecost: info?.sellerTeam?.basecost?.id,
        type: state.form.type,
        status: true,
      })
      state.catalogs = res as any[]
      state.catalogs.sort((a, b) => a.name.localeCompare(b.name))
      isLoading.value = false
    }

    const onSelectCatalogType = async () => {
      await getListProductCatalog()
    }
    const onSelectCatalog = async (catalogId: any) => {
      isLoading.value = true
      if (!catalogId) {
        state.selectedCatalog = {}
        return
      }
      const { data } = await getCatalog(catalogId)
      state.selectedCatalog = data as any

      let vTitles = productVariant.value?.title
        .split('/')
        .map((item: string) => item.trim())
        .filter(Boolean)

      await state.selectedCatalog?.variants?.forEach((element: any) => {
        let sizeName = element.size.name || ''
        // let colorName = element.color.name || ''
        if (vTitles.includes(sizeName)) {
          element.suggest = true
        }
      })
      isLoading.value = false
      onClickShowMore()
    }

    const onClickShowMore = () => {
      isShowAllVariant.value = !isShowAllVariant.value
      const vTitles = (productVariant.value?.title ?? '')
        .trim()
        .split('/')
        .map((item: string) => item.trim())
        .filter(Boolean)

      variants.value = state.selectedCatalog?.variants?.filter(
        (element: any) => {
          const sizeName = (element.size?.name ?? '').trim()
          return isShowAllVariant.value || vTitles.includes(sizeName)
        }
      )

      if (!variants.value?.length) {
        variants.value = state.selectedCatalog?.variants
      }
    }

    const onChooseVariantCode = (row: any) => {
      const designQty = detectDesignQty(state.selectedCatalog, row)
      const {
        _id: catalogId,
        name: catalogName,
        designs: catalogDesigns,
        productCode,
      } = state.selectedCatalog

      context.emit('callbackChangeVariantCode', {
        variantCode: row.catalogVariantCode,
        designQty: designQty,
        selectedCatalog: {
          catalogId,
          catalogName,
          catalogDesigns,
          productCode,
        },
        selectedVariant: row,
        productVariant: productVariant.value,
      })
      close()
    }

    const detectDesignQty = (catalog: any, variant: any) => {
      let result = 0
      if (variant.designs?.length > 0) {
        result = variant.designs?.length
      } else if (catalog?.designs?.length > 0) {
        result = catalog?.designs?.length
      }
      return result
    }

    const detectDesignName = (catalog: any, variant: any) => {
      let result: any[] = []
      if (variant.designs?.length > 0) {
        const designTemplates = variant.designs
        designTemplates.forEach((item: any) => result.push(item))
      } else if (catalog?.designs?.length > 0) {
        const designTemplates = catalog.designs
        designTemplates.forEach((item: any) => result.push(item.name))
      }
      return result
    }

    const toggle = async (variant: any, suggestCatalog: any) => {
      showModalVisible.value = true
      productVariant.value = variant
      getListProductCatalog()
      if (suggestCatalog) {
        state.form.catalog = suggestCatalog
        onSelectCatalog(suggestCatalog)
      }
    }

    const close = () => {
      showModalVisible.value = false
    }

    return {
      toggle,
      close,
      showModalVisible,
      getListProductCatalog,
      state,
      isLoading,
      info,
      onSelectCatalog,
      getCatalog,
      formatterDollars,
      onChooseVariantCode,
      detectDesignQty,
      detectDesignName,
      catalogTypes,
      onSelectCatalogType,
      variants,
      isShowAllVariant,
      onClickShowMore,
      productVariant,
    }
  },
})
