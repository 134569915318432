
import { defineComponent, ref, watch } from 'vue'
import { ARTWORK_SPACES } from '@/utils/constants'
import UploadImage from '@/pages/seller/orders/components/UploadImage.vue'
import UploadDesign from '@/pages/seller/orders/components/UploadDesign.vue'
import { useNotification } from '@/composables'

export default defineComponent({
  components: { UploadImage, UploadDesign },
  setup(props, context) {
    const { success } = useNotification()

    const dialogVisible = ref<boolean>(false)
    const isLoading = ref<boolean>(true)
    const productVariant = ref()

    const callbackDesign = () => {
      //
    }

    const changeArtwork = async (data: any) => {
      isLoading.value = true
      await (productVariant.value[data.artwork] = data.path)
      isLoading.value = false
      success('Uploaded!')
    }

    const uploaded = async (data: any) => {
      await changeArtwork(data)
    }

    const onSave = () => {
      context.emit('callbackUploadArtworks', {
        variant: productVariant.value,
      })
      dialogVisible.value = false
    }

    const toggle = (variant: any) => {
      isLoading.value = true
      if (!variant.length) {
        productVariant.value = { ...variant }
      } else {
        productVariant.value = {}
      }
      dialogVisible.value = true
      isLoading.value = false
    }

    const close = () => {
      dialogVisible.value = false
    }

    watch(dialogVisible, () => {
      if (!dialogVisible.value) {
        productVariant.value = {}
      }
    })

    return {
      toggle,
      close,
      dialogVisible,
      ARTWORK_SPACES,
      productVariant,
      callbackDesign,
      changeArtwork,
      uploaded,
      isLoading,
      onSave,
    }
  },
})
