
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import ProductVariants from './components/ProductVariants.vue'
import UploadImage from '@/components/common/UploadImage.vue'
import { computed, reactive, defineComponent, ref } from 'vue'
import userProductRepositories from '@/repositories/userProductRepositories'
import { Product } from '@/interfaces/product'
import { useRoute, useRouter } from 'vue-router'
import { formatTime } from '@/utils/dateTime'
import { useNotification } from '@/composables'
import { imageUrl } from '@/utils/image'
import useMappingReponsitories from '@/repositories/useMappingReponsitories'
import { ElMessageBox } from 'element-plus'
import SaveButton from '@/components/common/buttons/SaveButton.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    ProductVariants,
    UploadImage,
    SaveButton,
    CloseButton,
  },
  name: 'Edit Product',
  setup() {
    const breadcrumbs = [
      {
        text: `Products`,
        link: 's.products',
        params: null,
      },
      {
        text: `Detail`,
        link: '',
        params: null,
      },
    ]
    const title = 'Seller'
    const { success, error } = useNotification()
    const {
      updatePersonalizeStatus,
      syncVariantDataToOrderItem,
      exportVariants,
    } = userProductRepositories()
    const product = reactive<Product>({
      agency: '',
      mockup: '',
      options: [],
      otherMockup: [],
      productType: '',
      sellerTeam: '',
      serverId: '',
      optionHash: '',
      title: '',
      handle: '',
      variants: [],
    })
    const content = computed(() => {
      return { breadcrumbs, title }
    })

    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const orders = reactive([])
    const artworkSpaces = ref(['artwork1', 'artwork2', 'artwork3', 'artwork4'])

    const callbackMockup = function (setData: any, files: string[]) {
      setData.mockup = files[0]
    }

    const callbackOtherMockup = function (setData: any, files: string[]) {
      setData.otherMockup = files
    }

    const callBackVariants = function (variants: []) {
      product.variants = variants
    }

    const { onSelectCatalogCodeOrderItem } = useMappingReponsitories()

    const updateProductCodeToProduct = async (productId: string, data: any) => {
      if (!productId || !data) {
        return
      }
      const dto = {
        variantTitle: data.productVariant?.title,
        catalogCode: data.variantCode,
        catalog: data.selectedCatalog,
        catalogVariant: data.selectedVariant,
        designQty: data.designQty,
      }
      const res = await onSelectCatalogCodeOrderItem(productId, dto)
      if (res?.status === 200) {
        success('Updated!')
      }
    }

    const callbackUpdateVarCode = async (data: any) => {
      const productId = route.params.id as string
      await updateProductCodeToProduct(productId, data)
    }

    const fetchData = async () => {
      isLoading.value = true
      const { getProductById } = userProductRepositories()
      const { data } = await getProductById(route.params.id)
      Object.assign(product, data)
      isLoading.value = false
    }

    fetchData()

    const updateProduct = async () => {
      const { updateProduct } = userProductRepositories()
      const { status } = await updateProduct(product)
      if (status === 200) {
        fetchData()
        success('Update success!')
      }
    }
    const dialog = ref<boolean>(false)
    const confirmDelete = async () => {
      const { deleteProductById } = userProductRepositories()
      const { status } = await deleteProductById(product)
      if (status === 200) {
        router.push({ name: 's.products' })
      }
    }
    const cancelDelete = () => {
      dialog.value = false
    }
    const discardProduct = () => {
      window.location.reload()
    }
    const deleteProduct = () => {
      dialog.value = true
    }

    const isSyncToItem = ref(false)
    const syncToOrderItem = async () => {
      isSyncToItem.value = true
      const productId: string = route.params.id as string
      const res: any = await syncVariantDataToOrderItem(productId)
      if (res?.status === 201 || res?.status === 200) {
        success('Sync success!')
      }
      isSyncToItem.value = false
    }

    const loadingExport = ref(false)
    const onExporting = async () => {
      try {
        loadingExport.value = true
        const res = (await exportVariants(route.params.id)) as any
        loadingExport.value = false
        window.open(res?.data?.url, '_blank')
        success(res?.data?.message || `Export variants success`)
      } catch (e) {
        error()
      }
    }
    const confirmExportOrder = async () => {
      ElMessageBox.confirm(
        `Confirm to export ${product.variants?.length} variants?`,
        'Warning',
        {
          confirmButtonText: 'Export',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        await onExporting()
      })
    }

    return {
      product,
      isSyncToItem,
      orders,
      content,
      isLoading,
      route,
      router,
      updateProduct,
      discardProduct,
      dialog,
      confirmDelete,
      cancelDelete,
      deleteProduct,
      callbackMockup,
      callbackOtherMockup,
      callBackVariants,
      formatTime,
      artworkSpaces,
      imageUrl,
      callbackUpdateVarCode,
      updateProductCodeToProduct,
      syncToOrderItem,
      confirmExportOrder,
    }
  },
})
